import _ from "lodash";
import { api } from "../../server/request";
import { handleError, handleResponse } from "../../actions/responseUtil";
import { toast } from "react-toastify";

const ACTION_TYPES = {
    SET_LOADING: "starlinkPooled/setLoading",
    GET_POOLED_USAGE_PIE: "starlinkPooled/getPooledUsagePie",
    GET_POOLED_USAGE_BAR_CHART: "starlinkPooled/getPooledUsageBarChart",
    GET_POOLED_USAGE_TOP_CARDS: "starlinkPooled/getPooledUsageTopCards",
    GET_POOLED_USAGE_LINE_CHART: "starlinkPooled/getPooledUsageLineChart",
    GET_POOLED_USAGE_TABLE: "starlinkPooled/getPooledUsageTable",
    GET_POOLED_USAGE_PIE_SPLIT: "starlinkPooled/getPooledUsagePieSplit",
     GET_STARLINK_PLAN_TEMPLATE: "starlinkPooled/getStarlinkPlanTemplate",
     CREATE_PLAN_TEMPLATE: "starlinkPooled/createPlanTemplate",
     GET_STARLINK_PLAN_HIERARCHY: "starlinkPooled/getStarlinkPlanHierarchy",
     ASSIGN_CUSTOM_PLAN: "starlinkPooled/assignCustomPlan",
     ASSIGN_CHILD_POOL: "starlinkPooled/assignChildPool",
     GET_SERVICE_LINE_PER_PLAN: "starlinkPooled/getServiceLinePerPlan",
     GET_SERVICE_LINE_PER_PLAN_RIGHT:"starlinkPooled/getServicePlanPerPlanRight",
     MOVE_SERVICE_PLAN:"starlinkPooled/moveServicePlan",
     GET_SERVICE_PLAN_TOP_WIDGET: "starlinkPooled/getServicePlanTopWidget",
}

const initialState = {
    pooledUsagePie: [],
    pooledBarChart: [],
    pooledTopCards: [],
    pooledLineChart: [],
    pooledTable: [],
    pooledUsagePieSplit: [],
    gettingPooledUsagePie: false,
    gettingPooledBarChart: false,
    gettingPooledTopCards: false,
    gettingPooledlineChart: false,
    gettingPooledTable: false,
    gettingPooledUsagePieSplit: false,
    creatingPlanTemplate: false,
    createPlanTemplate: [],
    starlinkPlanTemplate: [],
    starlinkPlanHierarchy:[],
    assignCustomPlanToPool: [],
    assignChildPool:[],
    serviceLinePlans:[],
    serviceLinePlansRight:[],
    servicePlanTopWidget: []
}

export function starlinkPooledReducer(state: any = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.GET_POOLED_USAGE_PIE: {
            return {
                ...state,
                pooledUsagePie: action.payload?.data,
            }
        }
        case ACTION_TYPES.GET_POOLED_USAGE_BAR_CHART: {
            return {
                ...state,
                pooledBarChart: action.payload?.data,
            }
        }
        case ACTION_TYPES.GET_POOLED_USAGE_TOP_CARDS: {
            return {
                ...state,
                pooledTopCards: action.payload,
            }
        }
        case ACTION_TYPES.GET_POOLED_USAGE_LINE_CHART: {
            return {
                ...state,
                pooledLineChart: action.payload?.data,
            }
        }
        case ACTION_TYPES.GET_POOLED_USAGE_TABLE: {
            return {
                ...state,
                pooledTable: action.payload?.data,
            }
        }
        case ACTION_TYPES.GET_POOLED_USAGE_PIE_SPLIT: {
            return {
                ...state,
                pooledUsagePieSplit: action.payload?.data,
            }
        }
        case ACTION_TYPES.GET_STARLINK_PLAN_TEMPLATE: {
            return {
                ...state,
                starlinkPlanTemplate: action.payload?.data,
            }
        }
        case ACTION_TYPES.GET_STARLINK_PLAN_HIERARCHY: {
            return {
                ...state,
                starlinkPlanHierarchy: action.payload?.data,
            }
        }
        case ACTION_TYPES.GET_SERVICE_LINE_PER_PLAN: {
            return {
                ...state,
                serviceLinePlans: action.payload?.data,
            }
        }
        case ACTION_TYPES.GET_SERVICE_LINE_PER_PLAN_RIGHT: {
            return {
                ...state,
                serviceLinePlansRight: action.payload?.data,
            }
        }
        case ACTION_TYPES.CREATE_PLAN_TEMPLATE: {
            return {
                ...state,
                createPlanTemplate: action.payload,
            }
        }
        case ACTION_TYPES.ASSIGN_CUSTOM_PLAN: {
            return {
                ...state,
                assignCustomPlanToPool: action.payload,
            }
        }
        case ACTION_TYPES.MOVE_SERVICE_PLAN: {
            return {
                ...state,
                moveServicePlan: action.payload,
            }
        }
        case ACTION_TYPES.ASSIGN_CHILD_POOL: {
            return {
                ...state,
                assignChildPool: action.payload,
            }
        }
        case ACTION_TYPES.GET_SERVICE_PLAN_TOP_WIDGET: {
            return {
                ...state,
                servicePlanTopWidget: action.payload?.data && action.payload?.data?.length > 0 ? action.payload?.data[0] : {},
            }
        }
        case ACTION_TYPES.SET_LOADING: {
            return {
                ...state,
                ...action.payload
            }
        }
        default:
            return state
    }
}

export interface getPooledUsageParameters {
    dpId: string;
    chartStartDate: string;
    chartEndDate: string;
    limit: number;
    offset: number;
    searchText: string;
    sortBy: string;
    sortOrder: string;
    source: string;
    accounts: string[];
    usageSource:string
}

export const getPooledUsageTable = (parameters: getPooledUsageParameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledTable: true } });
    let _parameters = {
        dpId: parameters.dpId,
        chartStartDate: parameters.chartStartDate,
        chartEndDate: parameters.chartEndDate,
        limit: parameters.limit,
        offset: (parameters.offset - 1) * parameters.limit,
        searchText: parameters.searchText,
        sortBy: parameters.sortBy,
        sortOrder: parameters.sortOrder,
        accounts: parameters?.accounts?.map(acc => `'${acc}'`).join(',')
    };
    const data = {
        query: parameters.source == 'aggregation_table' ? 'GET_STARLINK_ORGANIZATION_USAGE_AGG_CH' : `GET_STARLINK_ORGANIZATION_USAGE_DIRECT_CH`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_POOLED_USAGE_TABLE)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledTable: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledTable: false } });
        });
}

export const getPooledUsagePie = (parameters: getPooledUsageParameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePie: true } });
    let _parameters = {
        dpId: parameters.dpId,
        chartStartDate: parameters.chartStartDate,
        chartEndDate: parameters.chartEndDate,
        limit: parameters.limit,
        offset: parameters.offset,
        source: parameters?.source,
        accounts: parameters?.accounts?.map(acc => `'${acc}'`).join(','),
    };
    const data = {
        query: `GET_STARLINK_ORGANIZATION_USAGE_PIE_CH`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_POOLED_USAGE_PIE)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePie: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePie: false } });
        });
}

export const getPooledBarChart = (parameters: getPooledUsageParameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledBarChart: true } });
    let _parameters = {
        dpId: parameters.dpId,
        chartStartDate: parameters.chartStartDate,
        chartEndDate: parameters.chartEndDate,
        source: parameters.source,
        limit: parameters.limit,
        offset: parameters.offset,
        accounts: parameters?.accounts?.map(acc => `'${acc}'`).join(',')
    };

    const data = {
        query: `GET_STARLINK_ORGANIZATION_USAGE_STACKED_CHART_CH`,
        named: true,
        format: 'chart',
        groupBy: "organizationName",
        tsColumn: "date",
        series: "chartUsage",
        annotation: "asset_name",
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_POOLED_USAGE_BAR_CHART)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledBarChart: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledBarChart: false } });
        });
}

export const getPooledTopCards = (parameters: getPooledUsageParameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledTopCards: true } });
    let _parameters = {
        dpId: parameters.dpId,
        accounts: parameters?.accounts?.map(acc => `'${acc}'`).join(','),
    };

    const data = {
        query: `GET_STARLINK_ORGANIZATION_USAEGE_TOPCARD`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_POOLED_USAGE_TOP_CARDS)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledTopCards: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledTopCards: false } });
        });
}

export const getPooledLineChart = (parameters: getPooledUsageParameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledLineChart: true } });
    let _parameters = {
        dpId: parameters.dpId,
        accounts: parameters?.accounts?.map(acc => `'${acc}'`).join(','),
        chartStartDate: parameters?.chartStartDate,
        chartEndDate: parameters?.chartEndDate,
        usageSource: parameters?.usageSource
    };

    const data = {
        query: `GET_STARLINK_ORGANIZATION_USAGE_BURNOUT_CH`,
        named: true,
        format: 'chart',
        groupBy: "usage_type",
        tsColumn: "date",
        series: "usage_value",
        annotation: "asset_name",
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_POOLED_USAGE_LINE_CHART)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledLineChart: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledLineChart: false } });
        });
}

export const getPooledUsagePieSplit = (parameters: any) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePieSplit: true } });
    let _parameters = {
        dpId: parameters.dpId,
        limit: parameters.limit,
        offset: parameters.offset,
        accounts: parameters?.accounts?.map(acc => `'${acc}'`).join(','),
    };
    const data = {
        query: `GET_STARLINK_ORGANIZATION_USAGE_SPLIT_PIE_CH`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_POOLED_USAGE_PIE_SPLIT)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePieSplit: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePieSplit: false } });
        });
}

export const getStarlinkTemplate = (parameters: any) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePieSplit: true } });
    let _parameters = {
        dpId: parameters.dpId,
        plan_filter: parameters.plan_filter,
         search: parameters.searchValue
    };
    const data = {
        query: `GET_STARLINK_PLAN_TEMPLATES`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_STARLINK_PLAN_TEMPLATE)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePieSplit: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePieSplit: false } });
        });
}

export const getStarlinkPlanHierarchy = (parameters: any) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePieSplit: true } });
    let _parameters = {
        dp_id: parameters.dpId,
        plan_filter: parameters.plan_filter,
        search: parameters.searchValue
    };
    const data = {
        query: `STARLINK_GET_PLAN_HIERARCHY`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_STARLINK_PLAN_HIERARCHY)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePieSplit: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePieSplit: false } });
        });
}

export const getServiceLinePerPlan = (parameters: any) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePieSplit: true } });
    let _parameters = {
        dp_id: parameters.dp_id,
        custom_plan_id: parameters.custom_plan_id,
        org_filter: "",
        search: parameters?.search,
        page_limit: parameters?.page_limit,
        page_offset: parameters?.page_offset
    };
    const data = {
        query: `GET_STARLINK_ASSIGNED_SERVICE_LINES`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_SERVICE_LINE_PER_PLAN)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePieSplit: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePieSplit: false } });
        });
}

export const getServiceLinePerPlanRight = (parameters: any) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePieSplit: true } });
    let _parameters = {
        dp_id: parameters.dp_id,
        custom_plan_id: parameters.custom_plan_id,
        org_filter: "",
        search: parameters?.search,
        page_limit: parameters?.page_limit,
        page_offset: parameters?.page_offset
    };
    const data = {
        query: `GET_STARLINK_ASSIGNED_SERVICE_LINES`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_SERVICE_LINE_PER_PLAN_RIGHT)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePieSplit: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePieSplit: false } });
        });
}

const showToastMessage = (res, handler) => {
   if(res.success == false) {
    toast.error(`${handler} : ${res.message}, Please Try Later.`, {
        position: toast.POSITION.BOTTOM_LEFT,
      })
   } else {
    toast.success(res.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      })
   }
}


export const createTemplate = (parameters) => (dispatch) => {
    if (!parameters?.dpId) return;
    
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingPlanTemplate: true } });
  
    return api.starlink.post(`/planTemplate`, parameters, {})
      .then((res) => {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingPlanTemplate: false } });
        dispatch({ type: ACTION_TYPES.CREATE_PLAN_TEMPLATE, payload: res });
        showToastMessage(res, 'Plan Template Created Successfully');
        return res;
      })
      .catch((error) => {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingPlanTemplate: false } });
        return error;
      });
  };
  


  export const assignCustomPlanAPI = (parameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingPlanTemplate: true } });
  
    return api.starlink.post(`/customPlans`, parameters, {})
      .then((res) => {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingPlanTemplate: false } });
        dispatch({ type: ACTION_TYPES.ASSIGN_CUSTOM_PLAN, payload: res });
        showToastMessage(res, 'Plan Assigned Successfully');
        return res;
      })
      .catch((error) => {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingPlanTemplate: false } });
        return error;
      });
  };
  

  export const assignChildPoolAPI = (parameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingPlanTemplate: true } });
  
    return api.starlink.post(`/parentPools/${parameters?.parentPoolId}/childPools`, parameters, {})
      .then((res) => {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingPlanTemplate: false } });
        dispatch({ type: ACTION_TYPES.ASSIGN_CHILD_POOL, payload: res });
        showToastMessage(res, 'Pool Assigned Successfully');
        return res;
      })
      .catch((error) => {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingPlanTemplate: false } });
        return error;
      });
  };

  export const moveServicePlanAPI = (parameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingPlanTemplate: true } });
  
    return api.starlink.put(`/service_lines/move_service_plan`, parameters, {})
      .then((res) => {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingPlanTemplate: false } });
        dispatch({ type: ACTION_TYPES.MOVE_SERVICE_PLAN, payload: res });
        showToastMessage(res, 'Plan Moved Successfully');
        return res;
      })
      .catch((error) => {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingPlanTemplate: false } });
        return error;
      });
  };
  

export const getStarlinkPlanTopWidget = (parameters: any) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePieSplit: true } });
    let _parameters = {
        dp_id: parameters.dp_id,
        plan_filter: parameters.plan_filter
    };
    const data = {
        query: `GET_STARLINK_PLANS_TOP_WIDGET`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_SERVICE_PLAN_TOP_WIDGET)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePieSplit: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePieSplit: false } });
        });
}

export const clearComponentData = () => (dispatch) => {
    dispatch({ type: ACTION_TYPES.GET_STARLINK_PLAN_HIERARCHY, payload: { data: [] } })
    dispatch({ type: ACTION_TYPES.GET_STARLINK_PLAN_TEMPLATE, payload: { data: [] } })
}