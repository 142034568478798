import { Autocomplete, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, List, ListItemButton, MenuItem, Paper, Select, Stack, TextField, Tooltip, Typography } from "@mui/material";
import close from "../../asset/image/close.svg";
import { useState, useEffect } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import momenttz from "moment-timezone";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import moment, { Moment } from "moment";
import { getStarlinkTemplate, createTemplate, getStarlinkPlanHierarchy } from "../StarlinkPooled/slice";
import { useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";


const EditActionsCreatePlanPopup = (props) => {
    const { createPlanDialog, setCreatePlanDialog, createTemplate, starlinkPlanTemplate, createPlanTemplate, authReducer} = props;
    const [planPoolToggle, setPlanPoolToggle] = useState("Plan")
    const handlePlanPoolToggle = (val) => {
        setPlanPoolToggle(val)
    }
    const deviceActionsList = [
        'Template Info',
        'Quota Actions',
        'Notifications'
    ].filter(action => action);

    const [selectedAction, setSelectedAction] = useState(deviceActionsList[0]);

    const handleChangeActionType = (actionType) => {
        setSelectedAction(actionType);
    }

    const [currentSubPool, setCurrentSubPool] = useState<any>({
        poolName: '',
        poolSize: '',
        unit: 'tb',
        startDate: null,
        duration: 'monthly',
        isRecurring: true,
        selectedDp: null,
        poolInfo: '',
        assignedBy: '',
        dpId: '',
        data: null,
    });

      const [revertEntries, setRevertEntries] = useState<any>([{ usagePercentage: 0, type: 'resumeServiceLine', revert: true }, { usagePercentage: 0, type: 'optIn', revert: true }]);
        const [entries, setEntries] = useState<any>([{ usagePercentage: '70', type: 'sendNotification' }, { usagePercentage: '100', type: 'pauseServiceLine' }]);
        const [emailInput, setEmailInput] = useState('');
        const [emails, setEmails] = useState<any>([]);
        const [error, setError] = useState('');

    const handleSubPoolChange = (field: any, value: any) => {
        setCurrentSubPool({
            ...currentSubPool,
            [field]: value,
        });
    };

    const getFirstDayOfMonth = () => {
        return momenttz().startOf('month').hours(0).minutes(0).seconds(0).milliseconds(0);
    };

    const handleValueChange = (index, field, value) => {
        const newEntries = [...entries];
        newEntries[index][field] = value;
        setEntries(newEntries);
    };

    const handleAddEntry = () => {
        setEntries([...entries, { usagePercentage: '', type: 'sendNotification' }]);
    };

    const validateEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    }

    const handleAddEmail = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && emailInput) {
            event.preventDefault();
            if (validateEmail(emailInput)) {
                setEmails([...emails, { name: emailInput.split('@')[0].replace('.', ' ').replace(/\b\w/g, char => char.toUpperCase()), email: emailInput }]);
                setEmailInput('');
                setError('');
            } else {
                setError('Invalid email address');
            }
        }
    };

    const handleRemoveEntry = (index) => {
        const updatedEntries = entries.filter((_, i) => i !== index);
        setEntries(updatedEntries);
    };

    const handleRemoveRevertEntry = (index) => {
        const updatedEntries = revertEntries.filter((_, i) => i !== index);
        setRevertEntries(updatedEntries);
    };

    const handleDeleteEmail = (emailToDelete: string) => {
        setEmails(emails.filter(email => email !== emailToDelete));
    };


    const handleRevertValueChange = (index, field, value) => {
        const newEntries = [...revertEntries];
        newEntries[index][field] = value;
        setRevertEntries(newEntries);
    };

    const handleAddRevertEntry = () => {
        setRevertEntries([...revertEntries, { usagePercentage: 0, type: 'optIn', revert: true }]);
    };

    const [createPlanRows, setCreatePlanRows] = useState<any>([
        {
            planName: '',
            parentPool: "",
            recurrence: true,
            duration: 'monthly',
            planUsage: '',
            billingDate: null,
            unit: 'gb'

        },
    ]);

       const clearData = () => {
        setCurrentSubPool([
               {  poolName: '',
                poolSize: '',
                unit: 'tb',
                startDate: null,
                duration: 'monthly',
                isRecurring: true,
                selectedDp: null,
                poolInfo: '',
                assignedBy: '',
                dpId: '',
                data: null},
            ])
            setEmails([]);
            setEntries([{ usagePercentage: '70', type: 'sendNotification' }, { usagePercentage: '100', type: 'optOut' },]);
        }

       const handleCreatePlanTemplate = () => {
            let params = {
                planName: currentSubPool?.poolName,
                planSize: currentSubPool?.poolSize,
                startDate: getFirstDayOfMonth(),
                templateInfo: currentSubPool?.poolInfo,
                unit: currentSubPool?.unit,
                duration: currentSubPool?.duration,
                type: planPoolToggle === "Plan" ? "customPlan" : "orgPool" ,
                dpId: authReducer.selectedOu?.id,
                quotaActions: [...entries, ...revertEntries],
                usersToNotify: emails,
                isRecurring: currentSubPool?.isRecurring ? true : false,
                notificationMethods: ['email']
            }
            createTemplate(params)
        }

        
    useEffect(() => {
        if (!_.isEmpty(createPlanTemplate)) {
            clearData()
            setSelectedAction(deviceActionsList[0])
            getStarlinkTemplate({ dpId: authReducer.selectedOu?.id })
            setCreatePlanDialog(false)
        }

    }, [createPlanTemplate])


    return (
        <div className="Form">
            <Dialog
                disableEnforceFocus
                open={createPlanDialog}
                onClose={() => { setCreatePlanDialog(false); clearData() }}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                // id={getDrawerOpen ? 'popup-fixed-position-200px' : 'popup-fixed-position-77px'}
                className='config-deploy-inventory-popup menuScroll inventory-warehouse-register-pop-up inventory-edit-actions-container '
            >
                <div>
                    <DialogTitle className='create-default-config-title edit-actions-title'>
                        <Grid className="device-configuration-popup">
                            <span className="configurations">Pool/Plan Template</span>
                            <span className="selected-site-device device-configuration-popup-devicename">
                                {`Create ${planPoolToggle}`}
                            </span>
                        </Grid>
                        <Grid><img src={close} title="Close" onClick={() => { setCreatePlanDialog(false); clearData() }} /></Grid>
                    </DialogTitle>
                    <DialogContent className="edit-actions-content warehouse-edit-actions-content">
                        <Grid container spacing={1}>
                            <Grid className="menuScroll device-border-right inventory-deployed-device-list device-config-pop-config-list-menu" item>
                                <Grid className="actions-device-data-body">
                                    <List>
                                        {deviceActionsList && deviceActionsList.map((identifier, index) => {
                                            return <ListItemButton className={identifier == selectedAction ? "status-lists selectedAction " : "status-lists"} key={`device-${index}`} selected={identifier === selectedAction}
                                                onClick={() => handleChangeActionType(identifier)}>
                                                <Tooltip title={selectedAction}>
                                                    <Typography className={identifier === selectedAction ? "font-status-pop-up selectedAction" : "font-status-pop-up NotselectedAction"}>{identifier}</Typography>
                                                </Tooltip>
                                            </ListItemButton>
                                        })}
                                    </List>
                                </Grid>
                            </Grid>
                            <Grid className="menuScroll device-config-pop-config-list-menu-content">
                                <Grid className="device-data-body edit-actions-data-body">
                                    {selectedAction == 'Template Info' && <Stack className="sub-tab-parent" direction="column" spacing={1}>
                                        <Grid container className="config-apply-config-target" >
                                            <Grid xs={4} sm={4} md={4} lg={4}>Type</Grid>
                                            <Grid xs={6} sm={6} md={6} lg={6} className="configuration-form-build">
                                                <Select
                                                    fullWidth
                                                    size="small"
                                                    value={planPoolToggle}
                                                    onChange={(e) => handlePlanPoolToggle(e.target.value)}
                                                    displayEmpty
                                                    sx={{ width: 300, height: '37px', marginTop: '6px !important' }}
                                                >
                                                    <MenuItem value="" disabled>Select Type</MenuItem>
                                                    <MenuItem value="Pool">Pool</MenuItem>
                                                    <MenuItem value="Plan">Plan</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>
                                        <Grid container className="config-apply-config-target" >
                                            <Grid xs={4} sm={4} md={4} lg={4}>Name</Grid>
                                            <Grid xs={6} sm={6} md={6} lg={6} className="configuration-form-build">
                                                <TextField
                                                    value={currentSubPool?.poolName}
                                                    sx={{ width: 300 }}
                                                    onChange={(e) => handleSubPoolChange('poolName', e.target.value)}
                                                    size="small"
                                                    placeholder="For eg. Pool 500 GB"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container className="config-apply-config-target" >
                                            <Grid xs={4} sm={4} md={4} lg={4}>Size</Grid>
                                            <Grid xs={6} sm={6} md={6} lg={6} className="configuration-form-build" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                <TextField
                                                    fullWidth
                                                    type="number"
                                                    value={currentSubPool.poolSize}
                                                    onChange={(e) => handleSubPoolChange('poolSize', e.target.value)}
                                                    size="small"
                                                    placeholder="100"
                                                    sx={{ width: 145 }}
                                                />
                                                <Select
                                                    fullWidth
                                                    size="small"
                                                    value={currentSubPool.unit}
                                                    onChange={(e) => handleSubPoolChange('unit', e.target.value)}
                                                    displayEmpty
                                                    sx={{ width: 145, height: '37px',  }}
                                                >
                                                    <MenuItem value="gb">GB</MenuItem>
                                                    <MenuItem value="tb">TB</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>
                                        <Grid container className="config-apply-config-target" >
                                            <Grid xs={4} sm={4} md={4} lg={4}>Date</Grid>
                                            <Grid xs={6} sm={6} md={6} lg={6} className="configuration-form-build">
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DatePicker
                                                        value={momenttz().startOf('month').hours(0).minutes(0).seconds(0).milliseconds(0)}
                                                        onChange={(newValue: any) => handleSubPoolChange('startDate', newValue)}
                                                        // disabled={accountForTopup?.subPoolDetails}
                                                        slots={{
                                                            textField: TextField,
                                                        }}
                                                        slotProps={{
                                                            textField: {
                                                                InputProps: {
                                                                    style: {
                                                                        height: '37px',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        width: '300px',
                                                                    },
                                                                    readOnly: true,
                                                                },
                                                                InputLabelProps: {
                                                                    style: {
                                                                        lineHeight: 'normal',
                                                                    },
                                                                },
                                                            },
                                                        }}
                                                        readOnly
                                                        disableOpenPicker
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                        <Grid container className="config-apply-config-target" >
                                            <Grid xs={4} sm={4} md={4} lg={4}>Duration</Grid>
                                            <Grid xs={6} sm={6} md={6} lg={6} className="configuration-form-build">
                                                <Select
                                                    fullWidth
                                                    size="small"
                                                    value={currentSubPool.duration}
                                                    onChange={(e) => handleSubPoolChange('duration', e.target.value)}
                                                    displayEmpty
                                                    sx={{ width: 300, height: '37px', marginTop: '6px !important' }}
                                                >
                                                    <MenuItem value="" disabled>Select Duration</MenuItem>
                                                    <MenuItem value="monthly">Monthly</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>
                                        <Grid container className="config-apply-config-target" >
                                            <Grid xs={4} sm={4} md={4} lg={4}>Pool Info</Grid>
                                            <Grid xs={6} sm={6} md={6} lg={6} className="configuration-form-build">
                                                <TextField
                                                    value={currentSubPool?.poolInfo}
                                                    sx={{ width: 300 }}
                                                    onChange={(e) => handleSubPoolChange('poolInfo', e.target.value)}
                                                    size="small"
                                                    placeholder="Pool Information"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container className="config-apply-config-target" >
                                            <Grid xs={4} sm={4} md={4} lg={4}>Recurring</Grid>
                                            <Grid xs={6} sm={6} md={6} lg={6} className="configuration-form-build">
                                                <Checkbox
                                                    checked={currentSubPool.isRecurring}
                                                    onChange={(e) => handleSubPoolChange('isRecurring', e.target.checked)}
                                                    style={{ width: 'unset', color: 'rgb(25, 118, 210)' }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Stack>}
                                    {selectedAction == 'Quota Actions' && <Stack className="sub-tab-parent" direction="column" spacing={1}>
                                        <Grid container className="config-apply-config-target" >
                                            <Grid xs={12} sm={12} md={12} lg={12} className="configuration-form-build">
                                                <Grid container spacing={2}>
                                                    {entries && entries?.map((entry, index) => (
                                                        <Grid item xs={12} key={index} display="flex" alignItems="center" justifyContent="flex-start" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                            <Grid item xs={3}> <Typography>Quota Action </Typography></Grid>
                                                            <Grid item xs={2}>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        size="small"
                                                                        label="% used"
                                                                        variant="outlined"
                                                                        value={entry.usagePercentage}
                                                                        type="number"
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            if (/^\d*\.?\d*$/.test(value) && (value === "" || parseFloat(value) > 0)) {
                                                                                handleValueChange(index, 'usagePercentage', value);
                                                                            }
                                                                        }}
                                                                        inputProps={{
                                                                            inputMode: 'numeric',
                                                                            pattern: '[0-9]*',
                                                                            min: "0",
                                                                            step: "any"
                                                                        }}
                                                                        sx={{
                                                                            "& .MuiInputBase-root": {
                                                                                height: "37px",
                                                                            },
                                                                            "& .MuiInputBase-input": {
                                                                                height: "37px",
                                                                                padding: "5px 10px",
                                                                            },
                                                                        }}
                                                                    />

                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <Select
                                                                    label="Action"
                                                                    fullWidth
                                                                    size="small"
                                                                    value={entry.type}
                                                                    onChange={(e) => handleValueChange(index, 'type', e.target.value)}
                                                                    style={{ marginTop: '8px' }}
                                                                >
                                                                    <MenuItem value="sendNotification">Send Notification</MenuItem>
                                                                    <MenuItem value="pauseServiceLine">Pause Service Line</MenuItem>
                                                                    <MenuItem value="optOut">Mobile Priority (Opt Out)</MenuItem>
                                                                </Select>
                                                            </Grid>
                                                            <Grid item xs={2} display="flex" alignItems="center">
                                                                {index > 1 && (
                                                                    <IconButton
                                                                        onClick={() => handleRemoveEntry(index)}
                                                                        color="secondary"
                                                                    >
                                                                        <DeleteIcon sx={{ color: 'red' }} fontSize="small" />
                                                                    </IconButton>
                                                                )}
                                                                {index === entries?.length - 1 && (
                                                                    <IconButton
                                                                        onClick={handleAddEntry}
                                                                        color="primary">
                                                                        <AddCircleOutlineIcon />
                                                                    </IconButton>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                    { <Grid item xs={12}>
                                                        <Divider sx={{ marginRight: '20px' }} />
                                                    </Grid>}
                                                    { <Grid item xs={12} container spacing={2} style={{ marginTop: '10px' }}>
                                                        <Grid item xs={12} display="flex" alignItems="center" justifyContent="flex-start">
                                                            <Grid item xs={3}><Typography>Revert Action</Typography></Grid>
                                                            <Grid item xs={9} display="flex" flexDirection="column">
                                                                {revertEntries && revertEntries.map((entry, index) => (
                                                                    <Grid
                                                                        container
                                                                        key={index}
                                                                        spacing={2}
                                                                         className="revert-entry-grid"
                                                                    >
                                                                        <Grid item xs={9.3}>
                                                                            <Select
                                                                                label="Action"
                                                                                fullWidth
                                                                                size="small"
                                                                                value={entry.type}
                                                                                onChange={(e) => handleRevertValueChange(index, 'type', e.target.value)}
                                                                            >
                                                                                <MenuItem value="resumeServiceLine">Resume All Service Lines </MenuItem>
                                                                                <MenuItem value="optIn">Opt In All Service Lines </MenuItem>
                                                                            </Select>
                                                                        </Grid>

                                                                        <Grid item xs={1} display="flex" alignItems="center">
                                                                            <IconButton
                                                                                onClick={() => handleRemoveRevertEntry(index)}
                                                                                color="secondary"
                                                                            ><DeleteIcon sx={{ color: 'red' }} fontSize="small" />
                                                                            </IconButton>

                                                                            {revertEntries?.length < 2 && (
                                                                                <IconButton onClick={handleAddRevertEntry} color="primary">
                                                                                    <AddCircleOutlineIcon />
                                                                                </IconButton>
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}
                                                                {revertEntries?.length === 0 && (
                                                                    <Grid item style={{ paddingLeft: '10px' }}>
                                                                        <IconButton onClick={handleAddRevertEntry} color="primary">
                                                                            <AddCircleOutlineIcon />
                                                                        </IconButton>
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Stack>}
                                    {selectedAction == 'Notifications' && <Stack className="sub-tab-parent" direction="column" spacing={1}>
                                        <Grid container className="config-apply-config-target" >
                                            <Grid xs={3} sm={3} md={3} lg={3} className="strPlans-email-label">Email</Grid>
                                            <Grid xs={7} sm={7} md={7} lg={7} className="strPlans-email-container configuration-form-build starlink-modify-plan" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                <Paper
                                                    className="strPlans-email-paper"
                                                >
                                                    {emails?.map((email, index) => (
                                                        <Chip
                                                            key={index}
                                                            label={email?.email}
                                                            onDelete={() => handleDeleteEmail(email)}
                                                            sx={{ margin: '4px' }}
                                                        />
                                                    ))}
                                                    <TextField
                                                        fullWidth
                                                        variant="standard"
                                                        error={!!error}
                                                        placeholder="Press Enter to add email"
                                                        helperText={error}
                                                        value={emailInput}
                                                        onChange={(e) => setEmailInput(e.target.value)}
                                                        onKeyDown={handleAddEmail}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            className: "starPlans-email-textfield-input",
                                                        }}
                                                        className="email-textfield"
                                                    />
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Stack>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </ DialogContent>
                    <DialogActions>
                        <div className="register-edit-actions-confirm-btns" style={{ width: '100%' }}>
                            <Divider className="confirm-dialogue-new-divider" />
                            <div className="confirm-dialogue-new-btns">
                                <Button className="confirm-dialogue-cancel" onClick={() => { setCreatePlanDialog(false); clearData() }}> Cancel </Button>
                                <Button className={'confirm-dialogue-proceed'} onClick={()=>{handleCreatePlanTemplate()}}>Apply</Button>
                            </div>
                        </div>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    dpIds: state.starlinkCloud?.dpIds || [],
    warnings: state.starlinkDashboard?.warnings || [],
    createPlanTemplate: state.starlinkPooled.createPlanTemplate,
    starlinkPlanTemplate: state.starlinkPooled.starlinkPlanTemplate,
    starlinkPlanHierarchy: state.starlinkPooled.starlinkPlanHierarchy,
});

export default withRouter(connect(mapStateToProps, {
    getStarlinkTemplate,
    getStarlinkPlanHierarchy,
    createTemplate
})(EditActionsCreatePlanPopup));

